import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-737afa55"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "unsubscribe-login" }
const _hoisted_2 = { class: "unsubscribe-login__title" }
const _hoisted_3 = { class: "h1" }
const _hoisted_4 = { class: "unsubscribe-login__inputs-wrapper" }
const _hoisted_5 = { class: "unsubscribe-login__radios" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_CustomRadioButton = _resolveComponent("CustomRadioButton")!
  const _component_ButtonsWrap = _resolveComponent("ButtonsWrap")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("unsubscribe.title2")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Input, {
        id: "email",
        label: "Email",
        name: "EmailInput",
        focusedOutline: false,
        modelValue: _ctx.contact.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contact.email) = $event)),
        isDisabled: !!_ctx.contact.email
      }, null, 8, ["modelValue", "isDisabled"]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radios, (radio) => {
          return (_openBlock(), _createBlock(_component_CustomRadioButton, {
            key: radio.id,
            id: radio.id,
            value: radio.value,
            modelValue: _ctx.selected,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event)),
            class: "custom-checkbox_md custom-checkbox_reverse"
          }, null, 8, ["id", "value", "modelValue"]))
        }), 128))
      ])
    ]),
    _createVNode(_component_ButtonsWrap, {
      confirmTitle: _ctx.$t('continue'),
      cancelTitle: _ctx.$t('unsubscribe.cancelButton'),
      disabled: !_ctx.contact.email,
      onConfirm: _ctx.onConfirm,
      onCancel: _ctx.onCancel
    }, null, 8, ["confirmTitle", "cancelTitle", "disabled", "onConfirm", "onCancel"])
  ]))
}