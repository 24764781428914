import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36789bbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-radio-button" }
const _hoisted_2 = ["id", "value", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("input", {
        id: _ctx.id,
        type: "radio",
        value: _ctx.value,
        checked: _ctx.isChecked,
        class: "custom-radio-button__input",
        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target?.id)))
      }, null, 40, _hoisted_2),
      _createElementVNode("label", {
        class: "custom-radio-button__label h4",
        for: _ctx.id
      }, [
        _renderSlot(_ctx.$slots, "label", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.value), 1)
        ], true)
      ], 8, _hoisted_3)
    ])
  ]))
}