
import { Options, Vue } from "vue-class-component"

@Options({
  props: {
    value: {
      required: true
    },
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
  }
})
export default class CustomRadioButton extends Vue {
  id: string;
  value: string;
  modelValue: string;

  get isChecked() {
    return this.modelValue === this.id
  }
}
