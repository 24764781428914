
import { Options, Vue } from "vue-class-component"
import Input from "@/components/Input/index.vue"
import ButtonsWrap from "@/components/Button/ButtonsWrap.vue"
import { getContact } from "@/api/services/ContactService"
import { Contact } from "@/models/Contact"
import CustomRadioButton from "@/components/Checkbox/CustomRadioButton.vue"
import DeclineService from "@/api/services/DeclineService"
import { goToPortalUnsubscribe } from "@/api/services/InviteService"

@Options({
  components: {
    Input,
    ButtonsWrap,
    CustomRadioButton
  }
})
export default class UnsubscribeLogin extends Vue {
  radios = [
    {
      id: "ALL",
      value: "I don’t want to receive any more paid survey opportunities"
    },
    {
      id: "CURRENT",
      value: "I’m just not interested in this survey, but keep sending others"
    }
  ]
  selected: "ALL" | "CURRENT" = "ALL"

  contact = Contact.createDefault()

  get id(): string {
    return (this.$route.query.id as string) ?? ""
  }

  get contactId() {
    return (this.$route.query.contactId as string) ?? undefined
  }

  get status() {
    return (this.$route.query.status as string) ?? undefined
  }

  async created() {
    if (!this.id) return false

    const contact = await getContact(this.id as string)

    if (contact) {
      this.contact = Object.assign(this.contact, contact)
    }
  }

  async onConfirm() {
    if (!this.id) return false

    switch (this.selected) {
      case "ALL":
        if (this.contact.panelist) {
          goToPortalUnsubscribe(this.contact.email)
          return
        }

        this.$router.push({
          name: "unsubscribeReasons",
          query: {
            id: this.id,
            contactId: this.contactId,
            status: this.status
          }
        })

        break;
      case "CURRENT":
        await DeclineService.changeById(this.id)
        this.$router.push({
          name: "declineConfirm",
          query: {
            id: this.id
          }
        })
        break

      default:
        break
    }
  }

  onCancel() {
    this.$router.push({
      name: "unsubscribeCancel",
      query: {
        id: this.id
      }
    })
  }
}
