import axios from "axios";
import { Endpoint } from "@/api/endpoints";
import Common from "./Common";

export default class DeclineService extends Common {

  static async changeById(id: string): Promise<void | undefined> {
    const payload = {};
    try {
      await axios.post(Endpoint.SET_DECLINE().url, payload, {
        params: { id },
      });

      return
    } catch (error) {
      super.toError(error);
    }

  }
}

export const changeDecline = DeclineService.changeById;